import React, { Component } from 'react'
import { graphql } from 'gatsby'
import WatPage from 'src/components/WatPage'

import 'antd/dist/antd.css' // or 'antd/dist/antd.less'
import 'static/shared-style/normalize.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const pageQuery = graphql`
  query {
    product: allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "product" }, isShow: { eq: true } } }
    ) {
      edges {
        node {
          id
          rawMarkdownBody
          frontmatter {
            best_seller
            colorTone
            id
            image
            imageS3
            isShow
            price
            position
            descMobile
            best_seller
            category
            size
          }
        }
      }
    }
  }
`

export default class Template extends Component {
  componentDidMount() {
    // window.location = '/wat-phra-sri'
  }
  render() {
    const { product } = this.props.data
    return (
      <WatPage
        wat={{ frontmatter: { name: '' } }}
        product={product}
        isHomepage={true}
        watsList={[]}
      />
    )
  }
}
